<template>
  <div class="table-create">
    <div class="mt-3">

      <BTable
        :items="value"
        :fields="getFullFields"
        :bordered="true"
        id="create-table"
        :empty-text="emptyText ? emptyText : 'Нет элементов'"
        striped
        small
        show-empty
      >

        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: getColWidth(field.key) }"
          />
        </template>

        <template #cell()="data">

          <template v-if="getLabel(data).key === 'iterator'">
            {{data.index + 1}}
          </template>

          <template v-if="getLabel(data).type === 'number'">

            <template v-if="getLabel(data).key === 'price_without_vat'">
              <b-form-input
                ref="priceWithoutVatInput"
                type="number"
                v-model="prices[data.index]"
                @change="changeData(data.field.key, data.index, $event, true)"
                v-mask="decimalMask"
                trim
              />
            </template>

            <template v-else-if="getLabel(data).key === 'count'">
              <b-form-input
                type="number"
                :state="getFieldError( data.index, 'count' ) ? false : null"
                v-model="model[data.index].count"
                v-mask="decimalMask"
                trim
                :disabled="data.item.hasOwnProperty('nomenclature_id') ? !data.item.nomenclature_id : false"
                @change="changeData(data.field.key, data.index, $event, true, data.item)"
              />
            </template>

            <template v-else>
              <b-form-input
                type="number"
                :value="data.item[data.field.key]"
                @change="changeData(data.field.key, data.index, $event, true, data.item)"
                v-mask="decimalMask"
                trim
              />
            </template>

          </template>

          <template v-if="getLabel(data).type === 'text'">
            <b-form-input
              :state="( ( getLabel( data ).key === 'delivery_address' ) ? ( getFieldError( data.index, 'delivery_address' ) ? false : null ) : null )"
              :value="data.item[data.field.key]"
              @input="changeData(data.field.key, data.index, $event)"
              trim
            />
          </template>

          <template v-if="getLabel(data).type === 'select'">

            <template v-if="getLabel(data).key === 'nomenclature_id'">
              <v-select
                label="name"
                v-bind:class="{ 'v-select-error': getFieldError( data.index, 'nomenclature_id' ) }"
                v-model="selectedNomenclature[data.index]"
                :options="nomenclatureOptions"
                :appendToBody="true"
                :placeholder="selectedNomenclature[data.index] ? selectedNomenclature[data.index].name : ''"
                @search="getNomenclatureOptionsByQuery"
                @input="setNomenclatureId($event, data.index)"
              >
                <template #no-options>
                  Нет результатов.
                </template>
              </v-select>
            </template>

            <template v-if="getLabel(data).key === 'mnemocode_id'">
              <v-select
                label="mnemocode"
                v-model="selectedNomenclature[data.index]"
                :options="mnemocodeOptions"
                :appendToBody="true"
                :placeholder="selectedNomenclature[data.index] ? selectedNomenclature[data.index].mnemocode : ''"
                @search="getMnemocodeOptionsByQuery"
                @input="setNomenclatureId($event, data.index)"
              >
                <template #no-options>
                  Нет результатов.
                </template>
              </v-select>
            </template>

            <template v-if="getLabel(data).key === 'order_id'">
              <b-form-select
                :options="ordersOptions"
                :value="data.item[data.field.key]"
                @change="changeData(data.field.key, data.index, $event)"
                style="width: 150px"
              />
            </template>

            <template v-if="getLabel(data).key === 'consignment_id'">
              <b-form-select
                :options="consignmentsOptions"
                :value="data.item[data.field.key]"
                @change="changeData(data.field.key, data.index, $event)"
              />
            </template>

            <template v-if="getLabel(data).key === 'vat_rate'">
              <b-form-select
                :options="getVatRatesOptions"
                :value="data.item[data.field.key]"
                @change="changeData(data.field.key, data.index, $event)"
              />
            </template>

            <template v-if="getLabel(data).key === 'country'">
              <b-form-select
                :options="getCountriesOptions"
                :value="data.item[data.field.key]"
                @change="changeData(data.field.key, data.index, $event)"
              />
            </template>

            <template v-if="getLabel(data).key === 'payment_type'">
              <b-form-select
                :options="getPaymentTypesOptions"
                :value="data.item[data.field.key]"
                @change="changeData(data.field.key, data.index, $event)"
              />
            </template>

          </template>

          <template v-if="getLabel(data).type === 'date'">
            <b-form-datepicker
              :state="( ( getLabel( data ).key === 'delivery_time' ) ? ( getFieldError( data.index, 'delivery_time' ) ? false : null ) : null )"
              :value="data.item[data.field.key]"
              placeholder="Выберите дату"
              locale="ru-RU"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              @input="changeData(data.field.key, data.index, $event)"
            />
          </template>

          <template v-if="getLabel(data).key === 'delete'">
            <a
              v-if="value ? value.length > 1 : false"
              href="#"
              class="text-danger btn-link h1"
              @click.prevent="deleteItem(data.index)"
            >
              <b-icon icon="x" />
            </a>
          </template>

          <template v-if="getLabel(data).key === 'nomenclatureTotalPrice'">
            {{ getNomenclatureTotalPrice(data.index) }}
          </template>

          <template v-if="getLabel(data).key === 'unit_id'">
            {{ units[data.index] }}
          </template>

          <template v-if="getLabel(data).key === 'mnemocode'">
            {{ mnemocodes[data.index] }}
          </template>

          <template v-if="getLabel(data).key === 'order_date'">
            {{ getSelectedOrder(data.index).order_date | parseDate }}
          </template>

          <template v-if="getLabel(data).key === 'consignment_date'">
            {{ getSelectedConsignment(data.index).date | parseDate }}
          </template>

          <template v-if="getLabel(data).type === 'select_nomenclature_from_order'">
            <b-form-select
              :options="getNomenclaturesByData(data)"
              :value="getNomenclatureByData(data)"
              @change="setNomenclatureByOrderId(getLabel(data).key, data.index, $event)"
            />
          </template>

          <template v-if="getLabel(data).type === 'select_nomenclature_from_consignment'">
            <b-form-select
              :options="nomenclaturesFromConsignmentOptions(data.index).filter( el => !model.filter( ( el, idx ) => !( idx == data.index )  ).map( el => el.nomenclature_id ).includes( el.nomenclature.id ) )"
              :value="getSelectedNomenclatureFromConsignment(data.index)"
              @change="setNomenclatureByConsignmentId(getLabel(data).key, data.index, $event)"
            />
          </template>

          <template v-if="getLabel(data).key === 'amount_without_vat'">
            {{ getAmountWithoutVat(data.item) }}
          </template>

          <template v-if="getLabel(data).key === 'amount_with_vat'">
            {{ getAmountWithVat(data.item) }}
          </template>

          <template v-if="getLabel(data).key === 'price_without_vat' && getLabel(data).type === 'disabled'">
            {{ prices[data.index] }}
          </template>

        </template>

        <template #cell(comment)="data">
          <div class="d-flex align-items-center justify-content-center">
            <BIcon
              v-if="data.item.comment ? data.item.comment.length > 0 : false"
              icon="chat-right-text"
              variant="primary"
              style="width: 1.5rem;height: 1.5rem;cursor: pointer;"
              @click="openCommentModal(data.index)"
            />
            <BIcon
              v-else
              icon="plus-square-fill"
              variant="primary"
              style="width: 1.5rem;height: 1.5rem;cursor: pointer;"
              @click="openCommentModal(data.index)"
            />
          </div>
        </template>

      </BTable>

      <div
        v-if="withTotal"
        class="pt-2"
      >
        Всего сумма по документу: <strong>{{ getTotal() }} руб.</strong>
      </div>

    </div>

    <b-modal
      id="edit-comment"
      centered
      title="Добавить комментарий"
    >
      <b-form-textarea
        id="textarea"
        v-model.trim="comment"
        placeholder="Текст комментария"
      />

      <template #modal-footer="{ hide }">
        <b-button
          variant="outline-primary"
          @click="hide()"
        >
          Отмена
        </b-button>
        <b-button
          variant="primary"
          @click="saveComment"
        >
          Добавить
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "debounce";

import createNumberMask from "text-mask-addons/dist/createNumberMask";

const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: true,
  thousandsSeparatorSymbol: "",
});

export default {
  name: "TableCreate",
  props: {
    value: Array,
    fields: Array,
    emptyText: String,
    withTotal: String,
    withIterator: {
      type: Boolean,
      default: false,
    },
    orders: Array,
    consignments: Array,
    validations: [ Array, Object ]
  },
  computed: {
    ...mapGetters([
      "getVatRatesOptions",
      "getCountriesOptions",
      "getPaymentTypesOptions",
    ]),
    getFullFields() {
      let res = this.fields;
      res.push({
        key: "delete",
        label: "",
      });
      if (this.withIterator) {
        res.unshift({
          key: "iterator",
          label: "№",
        });
      }
      return res;
    },
    ordersOptions() {
      return this.orders.map((order) => {
        return {
          value: order.id,
          text: order.number,
        };
      });
    },
    consignmentsOptions() {
      return this.consignments.map((consignment) => {
        return {
          value: consignment.id,
          text: consignment.number,
        };
      });
    },
  },
  watch: {
    value(val) {
      this.dataIsLoaded = true;
      this.model = val;
      val.forEach((position, index) => {
        if (!this.units[index]) {
          this.units.push(null);
        }
        if (!this.prices[index]) {
          this.prices.push(null);
        }
        if (!this.mnemocodes[index]) {
          this.mnemocodes.push(null);
        }
      });
    },
    dataIsLoaded(val) {
      if (val) {
        this.setEditData();
      }
    },
    // prices(val) {
    //   if (
    //     Object.prototype.hasOwnProperty.call(this.model[0], "price_without_vat")
    //   ) {
    //     this.$refs.priceWithoutVatInput?.$emit("change");
    //     val.forEach((price, index) => {
    //       this.changeData("price_without_vat", index, price, true);
    //     });
    //   }
    // },
  },
  data() {
    return {
      model: this.value,
      decimalMask: currencyMask,
      comment: "",
      commentIndex: -1,
      nomenclatureOptions: [],
      mnemocodeOptions: [],
      units: [null],
      prices: [null],
      mnemocodes: [null],
      selectedOrders: [null],
      mnemocodeValues: [],
      dataIsLoaded: false,
      selectedNomenclature: [{}],
    };
  },
  methods: {
    getLabel(data) {
      return this.getFullFields.filter(
        (item) => item.key === data.field.key
      )[0];
    },
    changeData(key, index, value, number) {
      if (number && key === "count") {
        const nomenclature_id = this.model[index]?.nomenclature_id;

        if (nomenclature_id) {
          let nomenclature = null;
          if (this.nomenclaturesFromOrderOptions(index).length) {
            nomenclature = this.nomenclaturesFromOrderOptions(index).filter(
              (item) => {
                if (item.nomenclature?.max_available_count) {
                  return (
                    item.nomenclature.id === nomenclature_id &&
                    item.price === this.model[index]?.price_without_vat
                  );
                } else {
                  return item.nomenclature.id === nomenclature_id;
                }
              }
            )[0];
          } else if (this.nomenclaturesFromConsignmentOptions(index).length) {
            nomenclature = this.nomenclaturesFromConsignmentOptions(
              index
            ).filter((item) => {
              if (item.nomenclature?.max_available_count) {
                return (
                  item.nomenclature.id === nomenclature_id &&
                  item.price === this.model[index]?.price_without_vat
                );
              } else {
                return item.nomenclature.id === nomenclature_id;
              }
            })[0];
          }
          if (nomenclature) {
            const max = nomenclature.nomenclature.max_available_count;

            if (typeof max === "number") {
              value = max >= value ? value : max;
            }
          }
        }
      }

      if (this.model[index]) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.model[index],
            "amount_without_vat"
          )
        ) {
          this.model[index].amount_without_vat = this.getAmountWithoutVat(
            this.model[index]
          );
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.model[index],
            "price_without_vat"
          )
        ) {
          this.model[index].price_without_vat = this.prices[ index ]
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.model[index],
            "amount_with_vat"
          )
        ) {
          this.model[index].amount_with_vat = this.getAmountWithVat(
            this.model[index]
          );
        }
      }

      if (this.model[index]) {
        this.model[index][key] = number ? parseFloat(value) : value;
        this.$emit("change", this.model);
      }
      if (index === this.model.length - 1) {
        if (!this.checkFieldsOnEmpty(this.model[index])) {
          this.addRow();
        }
      }
    },
    addRow() {
      const lastRowInModel = this.model[this.model.length - 1];
      if (!this.checkFieldsOnEmpty(lastRowInModel)) {
        this.$emit("addRow");
      }
    },
    checkFieldsOnEmpty(object) {
      let allFieldsIsEmpty = true;
      for (let key in object) {
        const field = object[key];
        if (field) {
          if (!isNaN(field) && field.toString().length > 0) {
            allFieldsIsEmpty = false;
          }
        }
      }
      return allFieldsIsEmpty;
    },
    deleteItem(index) {
      this.model.splice(index, 1);
      this.$emit("change", this.model);
    },
    openCommentModal(index) {
      this.comment = this.model[index].comment;
      this.commentIndex = index;
      this.$bvModal.show("edit-comment");
    },
    saveComment() {
      this.model[this.commentIndex].comment = this.comment;
      this.$emit("change", this.model);
      this.$bvModal.hide("edit-comment");
    },
    getTotal() {
      let res = 0;
      if (this.withTotal === "sum") {
        this.model.forEach((item, idx) => {
          res += Number(this.getNomenclatureTotalPrice(idx));
        });
      } else {
        this.model.forEach((item) => {
          res += Number(item[this.withTotal]);
        });
      }
      return res.toFixed(2);
    },
    getColWidth(key) {
      switch (key) {
        case "id":
          return "50px";
        case "iterator":
          return "50px";
        case "nomenclature_id":
          return "auto";
        case "mnemocode":
          return "110px";
        case "unit_id":
          return "70px";
        case "count":
          return "100px";
        case "nomenclaturePrice":
          return "120px";
        case "nomenclatureTotalPrice":
          return "130px";
        case "term":
          return "140px";
        case "comment":
          return "100px";
        case "delete":
          return "30px";
        default:
          return "auto";
      }
    },
    getNomenclatureById(id, index) {
      if (id) {
        this.$store.dispatch("getCurrentNomenclature", id).then((resp) => {
          this.setNomenclatureId(resp, index);
          this.$set(this.selectedNomenclature, index, resp);
        });
      }
    },
    getNomenclatureOptionsByQuery(search, loading) {
      if (search.length > 1) {
        loading(true);
        this.getNomenclatureOptionsSearch(search, loading, this);
      }
    },
    getMnemocodeOptionsByQuery(search, loading) {
      if (search.length > 1) {
        loading(true);
        this.getMnemocodeOptionsSearch(search, loading, this);
      }
    },
    getNomenclatureOptionsSearch: debounce(async function (
      search,
      loading,
      vm
    ) {
      vm.$store
        .dispatch("getNomenclatureSearch", search)
        .then((resp) => {
          this.nomenclatureOptions = resp;
        })
        .then(() => loading(false))
        .catch(() => loading(false));
    },
    800),
    getMnemocodeOptionsSearch: debounce(async function (search, loading, vm) {
      vm.$store
        .dispatch("getMnemocodeSearch", search)
        .then((resp) => {
          this.mnemocodeOptions = resp;
        })
        .then(() => loading(false))
        .catch(() => loading(false));
    }, 800),
    setNomenclatureId(value, index) {
      let modelItem = this.model[index];

      if (modelItem) {
        modelItem.nomenclature_id = value.id;

        if (Object.prototype.hasOwnProperty.call(modelItem, "unit_id")) {
          modelItem.unit_id = value.units[0].id;
        }
        this.$set(this.units, index, value.units[0].name);
        this.$set(this.prices, index, value.price);
        this.$set(this.mnemocodes, index, value.mnemocode);
        this.$emit("change", this.model);
        if (index === this.model.length - 1) {
          this.addRow();
        }
      }
    },
    setNomenclatureByOrderId(key, index, value) {
      if (value) {
        const nomenclature = this.nomenclaturesFromOrderOptions(index).filter(
          (item) => item.value === value
        )[0];

        if (value) {
          this.$set(this.prices, index, nomenclature.price);
          this.$set(this.mnemocodes, index, nomenclature.mnemocode);
          this.changeData(key, index, nomenclature.value_id);
        }
      }
    },
    setNomenclatureByConsignmentId(key, index, value) {
      if (value) {
        const nomenclature = this.nomenclaturesFromConsignmentOptions(
          index
        ).filter((item) => item.value === value)[0];

        this.$set(this.prices, index, nomenclature.price);
        this.$set(this.mnemocodes, index, nomenclature.mnemocode);
        this.changeData(key, index, nomenclature.value_id);
      }
    },
    getNomenclatureTotalPrice(index) {
      let res = 0;
      res = this.model[index]?.count
        ? this.model[index].count * this.prices[index]
        : 0;
      return res.toFixed(2);
    },
    setEditData() {
      this.value.forEach((valItem, valIndex) => {
        this.getNomenclatureById(valItem.nomenclature_id, valIndex);

        if (valItem.nomenclature && valItem.nomenclature.mnemocode) {
          this.mnemocodes[valIndex] = valItem.nomenclature.mnemocode;
        }
      });
    },
    getSelectedOrder(index) {
      let res = this.orders.filter((item) => {
        return this.model[index].order_id === item.id;
      });
      return res[0] ? res[0] : {};
    },
    getSelectedConsignment(index) {
      let res = this.consignments.filter((item) => {
        return this.model[index].consignment_id === item.id;
      });
      return res[0] ? res[0] : {};
    },
    nomenclaturesFromOrderOptions(index) {
      let res = [];
      let order = this.orders?.filter((item) => {
        return this.model[index].order_id === item.id;
      })[0];
      if (order) {
        res = order.nomenclatures?.map((item) => {
          return {
            order_id: order.id,
            nomenclature: item,
            value_id: item.id,
            value: `${item.name} - Цена: ${item.price}`,
            text: `${item.name} - Цена: ${item.price}`,
            price: item.price,
            mnemocode: item.mnemocode,
          };
        });
      }
      res = res.filter((item) => {
        let can = true;
        this.model.forEach((modelItem, modelIndex) => {
          if (
            item.order_id === modelItem.order_id &&
            modelItem.nomenclature_id === item.nomenclature.id &&
            index !== modelIndex &&
            modelItem.price === item.price
          ) {
            can = false;
          }
        });
        return can;
      });

      return res;
    },
    nomenclaturesFromConsignmentOptions(index) {
      let res = [];
      let consignment = this.consignments.filter((item) => {
        return this.model[index].consignment_id === item.id;
      })[0];
      if (consignment) {
        res = consignment.nomenclatures?.map((item) => {
          return {
            nomenclature: item,
            value_id: item.id,
            value: `${item.name} - Цена: ${item.price}`,
            text: `${item.name} - Цена: ${item.price}`,
            price: item.price,
            mnemocode: item.mnemocode,
          };
        });
      }
      return res;
    },
    getAmountWithoutVat(item) {
      const total = item.count * item.price_without_vat;
      return isNaN(total) ? 0 : parseFloat(total.toFixed(2));
    },
    getAmountWithVat(item) {
      return item.vat_rate
        ? parseFloat(
            (this.getAmountWithoutVat(item) * item.vat_rate).toFixed(2)
          )
        : 0;
    },
    getSelectedNomenclatureFromConsignment(index) {
      let res = null;
      this.nomenclaturesFromConsignmentOptions(index).forEach(
        (nomenclature) => {
          if (+nomenclature.price === +this.model[index].price_without_vat) {
            res = nomenclature.value;
          }
        }
      );
      return res;
    },
    getSelectedNomenclatureFromOrder(index) {
      let res = null;
      this.nomenclaturesFromOrderOptions(index).forEach((nomenclature) => {
        if (+nomenclature.price === +this.model[index].price_without_vat) {
          res = nomenclature.value;
        }
      });
      return res;
    },
    getFieldError( index, field ) {
      try {
        return this.validations[ index ][ field ].$error
      } catch {
        return false
      }
    },
    getNomenclaturesByData( data ) {
      try {
        return this.nomenclaturesFromOrderOptions(data.index).filter( el => ( ( el.value_id == this.model[data.index].nomenclature_id ) || !this.model.filter( el => el.order_id === this.model[data.index].order_id ).map( el => el.nomenclature_id ).includes( el.value_id ) ) )
      } catch( error ) {
        return this.nomenclaturesFromOrderOptions(data)
      }
    },
    getNomenclatureByData( data ) {
      try {
        const target = this.getNomenclaturesByData(data).find(el=>(el.nomenclature&&el.nomenclature.id)==data.item.nomenclature.id)
        return ( target && target.value )
      } catch( error ) {
        return this.getSelectedNomenclatureFromOrder(data.index)
      }
    }
  },
  mounted() {
    this.setEditData();
  },
};
</script>
